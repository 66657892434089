.jq-toast-wrap {
    display: block;
    position: fixed;
    width: 350px;
    pointer-events: none !important;
    margin: 0;
    padding: 0;
    letter-spacing: normal;
    z-index: 9000 !important;
}


.jq-toast-wrap * {
    margin: 0;
    padding: 0;
}

.jq-toast-single {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 0px 0px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-family: arial, sans-serif;
    line-height: 17px;
    position: relative;
    pointer-events: all !important;
    background-color: #444444;
    color: white;
}


.close-jq-toast-single {
    position: absolute;
    top: 3px;
    right: 7px;
    font-size: 14px;
    cursor: pointer;
}

.jq-toast-single h2 {
    font-family: arial, sans-serif;
    font-size: 14px;
    margin: 0px 0px 7px;
    background: none;
    color: inherit;
    line-height: inherit;
    letter-spacing: normal;
}

.jq-toast-loaded {
    width: 100%;
}
.jq-toast-loader {
    display: block;
    position: absolute;
    top: -2px;
    height: 5px;
    width: 0%;
    left: 0;
    border-radius: 5px;
    background: red;
}
