.top-buffer {
    margin-bottom: 30px;
}

.sc-added-item {
    border-color: #5cb85c;
}

.sc-product-item {
    position: relative;
}

.card {
    height: 100%;
    position: relative;
    border-radius: unset;
}

.card-body {
    padding: 0 !important;
    overflow: hidden;
}

.image-wrapper {
    height: 150px;
    width: 150px;
}

.bg-success {
    background-color: #28bcad !important;
}

.quantity-badge {
    position: absolute;
    border-radius: 0 0 100% 0;
    height: 40px;
    padding: 5px 5px 10px 0px;
    min-width: 40px;
    left: 0px;
    top: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-quantity-badge {
    position: absolute;
    border-radius: 50px;
    height: 25px;
    min-width: 25px;
    right: -15px;
    top: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-wrapper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

img {
    vertical-align: middle;
    border-style: none;
}

.product-title {
    font-weight: 400;
}

.btn {
    box-shadow: none !important;
}
.btn-success {
    border-color: #28bcad !important;
    background-color: #28bcad !important;
}
.btn-success:hover {
    border-color: #289B8C !important;
    background-color: #289B8C !important;
}

.add-cart-btn {
    position: absolute;
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 50%;
    bottom: -15px;
    right: -5px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.product-img-placeholder-default {
    width: 130px;
    height: 120px;
    background-color: #F9F9F9;
}
