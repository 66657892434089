body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes zoom-in {
  0% {
    -webkit-transform: scale(.7);
    transform: scale(.7);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes zoom-in {
  0% {
    -webkit-transform: scale(.7);
    transform: scale(.7);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}

:root {
  /*basic colors*/
  --black: #22292f;
  --grey-darkest: #3d4852;
  --grey-darker: #606f7b;
  --grey-dark: #8795a1;
  --grey: #b8c2cc;
  --grey-light: #dae1e7;
  --grey-lighter: #f1f5f8;
  --grey-lightest: #f8fafc;
  --white: #ffffff;

  --text-primary: #1f242a;
  --text-secondary: #586677;
  --text-info: #717683;
  --primary-main: #136cf1;
  --primary-dark: #265891;
  --amber: #ff6f00;
  --green: #4caf50;
  --green-dark: #3ac190;
  --green-light: #acda7d;
  --critical: #f04e3e;
  --not-critical: #8b99a0;
  --higlight-text: #fbe9c4;
  --discover-higlight-text: #DEF3E3;

  --background-light: #f4f4f4;
  --background-selected: #edf5ff;
  --background-overlay-dark: rgba(81, 88, 93, 0.7);
  --surface-light: #ffffff;
  --text-primary-light: #171717;
  --text-secondary-light: #464a4e;
  --border-1-light: #a5a9ad;
  --border-2-light: #cbcccd;
  --border-3-light: #e3e4e5;
  --danger-light: #f44336;
  --success-light: #4caf50;
  --warning-light: #ffc107;
  --info-light: #90a4ae;

  /*constant lengths*/
  --topbar-height: 80px;
}
