#search {
    position: relative;
    padding-top: 10px;
    margin: -20px auto 0;
    display: flex;
    justify-content: center;
}

.selectpicker option {
    border: none;
    background-color: white;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #14B1B2;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
    padding-left: 0;
    margin-top: -20px;
    background: none;
}

.selectpicker {
    border: none;
    background-color: white;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #14B1B2;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    padding-left: 0;
    margin-top: -20px;
    background: none;
}

.flex-responsive {
    flex-direction: column;
}

.display-data {
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.hide-data {
    display: none;
}

.img-for-new-grid {
    height: 180px;
}

@media screen and (max-width:400px) {

    .img-for-new-grid {
        height: 120px;
    }

}

@media screen and (min-width:768px) {
    .flex-responsive {
        flex-direction: row;
    }

    .display-data {
        display: none;
    }

    .hide-data {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

}

.fancybox-button-close-responsive {
    border-radius: 50%;
    border: 2px solid white;
    position: absolute;
    border-radius: 50%;
    border: 2px solid white;
    right: -22px;
    top: -21px;
    background-color: black
}

@media screen and (max-width:500px) {
    .fancybox-button-close-responsive {
        margin-right: 25px;
    }
}

.fancybox-thumbs {
    top: auto;
    width: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
}

.fancybox-show-thumbs .fancybox-inner {
    right: 0;
    bottom: 95px;
}

.fancybox-thumbs__list {
    margin: 0 auto;
}

.thanks_pp {
    text-align: center;
}

.thanks_pp img {
    margin-top: 30px;
}

.content {
    width: 80%;
    margin: 3% auto 0 auto;
    height: 460px;
    background-color: #F5F5F5;
}

.content1 {
    background-color: #0f6470;
    text-align: center;
    padding: 2em;
}

.content1 h2 {
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
}

.content2 {
    background-color: #b5e6ae;
}

.content2-header1 {
    float: left;
    width: 27%;
    text-align: center;
    padding: 1.5em;
}

.content2-header1 p {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin: 0;
}

.content2-header1 span {
    font-size: 14px;
    font-weight: 400;
}

.shipment {
    width: 100%;
    margin-top: 10%;
}

span.line {
    height: 5px;
    width: 90px;
    background-color: #F5998E;
    display: block;
    position: absolute;
    top: 30%;
    left: 45%;
}

.confirm {
    text-align: center;
    width: 15%;
    position: relative;
    float: left;

}

.confirm .imgcircle,
.process .imgcircle,
.quality .imgcircle {
    background-color: #2da9bb;
}

.confirm span.line,
.process span.line {
    background-color: #2da9bb;
}

.content3 p {
    margin-left: -50%;
    font-size: 15px;
    font-weight: 600;
}

.imgcircle {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-color: #F5998E;
    position: relative;
}

.imgcircle img {
    height: 30px;
    position: absolute;
    top: 28%;
    left: 30%;
}

.process {
    position: relative;
    width: 15%;
    text-align: center;
    float: left;
}

.quality {
    position: relative;
    width: 15%;
    text-align: center;
    float: left;
}

.dispatch {
    position: relative;
    width: 15%;
    text-align: center;
    float: left;
}

.delivery {
    position: relative;
    width: 15%;
    text-align: center;
    float: left;
    margin-right: -5%;
}

.footer a,
a:active {
    color: grey;
    text-decoration: none;
    font-family: 'Tahoma', sans-serif;
}

.footer a:hover {
    color: #00c4ff;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
}

.footer {
    margin-top: 3%;
    text-align: center;
    font-weight: 100;
}

.footer p {
    color: grey;
    font-size: 15px;
    font-family: 'Tahoma', sans-serif;
    line-height: 25px;
}

.btns a {
    background: #0f6470;
    padding: 10px 24px;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
}

.btns div {
    background: #0f6470;
    padding: 10px 24px;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
}

.btns .whatsapp {
    background: #3bb566 !important
}

.d-info {
    margin-top: 37px;
}

.cart-pop-price {
    color: #51c1b2;
    font-weight: 600;
    margin-left: 14px;
}

@media(max-width:1920px) {
    span.line {
        width: 157px;
        left: 32%;
    }

    .shipment {
        margin-top: 6%;
        margin-left: 9%;
    }

    .content3 p {
        margin-left: -65%;
    }
}

@media(max-width:1680px) {
    .content3 p {
        margin-left: -60%;
    }

    span.line {
        width: 127px;
        left: 37%;
    }
}

@media(max-width:1600px) {
    span.line {
        width: 117px;
        left: 39%;
    }
}

@media(max-width:1440px) {
    .content3 p {
        margin-left: -53%;
    }

    span.line {
        width: 99px;
        left: 44%;
    }
}

@media (max-width: 1366px) {
    span.line {
        width: 90px;
        left: 45%;
    }

    .shipment {
        margin-top: 10%;
    }
}

@media (max-width: 1280px) {
    span.line {
        width: 80px;
        left: 48%;
        top: 29%;
    }
}

@media (max-width: 1080px) {
    .content {
        width: 75%;
    }

    span.line {
        width: 88px;
        left: 46%;
    }
}

@media (max-width: 1050px) {
    span.line {
        width: 84px;
        left: 47%;
    }
}

@media (max-width: 1024px) {
    .content {
        width: 77%;
    }

    .content3 p {
        font-size: 14px;
    }
}

@media (max-width: 991px) {
    .content {
        width: 80%;
    }

    span.line {
        width: 84px;
        left: 47%;
    }
}

@media (max-width: 900px) {
    .content {
        width: 85%;
    }

    span.line {
        width: 78px;
        left: 49%;
    }
}

@media (max-width: 800px) {
    .content2 {
        width: 100%
    }

    .content {
        width: 95%;
    }

    .content2-header1 p {
        margin: 0 0 0 -7%;
    }
}

@media (max-width: 768px) {
    .content2-header1 {
        width: 25%;
    }

    .content2-header1 p {
        margin: 0 -19% 0 -10%;
    }

    span.line {
        width: 72px;
        left: 51%;
    }
}

@media (max-width: 736px) {
    span.line {
        width: 62px;
        left: 55%;
    }
}

@media (max-width: 667px) {
    .content2-header1 p {
        font-size: 14px;
    }

    .content2-header1 span {
        font-size: 13px;
    }

    .shipment {
        margin-top: -9%;
    }

    .content3 p {
        font-size: 12px;
        margin-left: -35%;
    }

    .confirm {
        margin-left: 4%;
    }

    span.line {
        width: 49px;
        left: 60%;
    }
}

@media (max-width: 600px) {
    .content1 {
        padding: 1.2em;
    }

    .content2-header1 p {
        font-size: 13px;
    }

    .content2-header1 span {
        font-size: 12px;
    }

    .content2-header1 {
        width: 24%;
    }

    .imgcircle {
        height: 65px;
        width: 65px;
    }

    .imgcircle img {
        top: 26%;
        left: 27%;
    }

    .content3 p {
        margin-left: -38%;
        font-size: 11px;
    }

    .content {
        height: 395px;
    }

    span.line {
        width: 50px;
        left: 58%;
    }
}

@media (max-width: 568px) {
    .content {
        height: 380px;
    }

    .content1 {
        padding: 1em;
        overflow: hidden;
    }

    span.line {
        width: 56px;
        left: 47%;
    }

    .content2-header1 {
        width: 23%;
    }

    .imgcircle {
        height: 50px;
        width: 50px;
    }

    .imgcircle img {
        height: 25px;
        top: 27%;
        left: 25%;
    }

    .content3 p {
        font-size: 10px;
        margin-left: -46%;
    }

    .confirm {
        margin-left: 0%;
    }

}

@media (max-width: 414px) {
    .header {
        margin-top: 8%;
    }

    .content {
        width: 320px;
        height: 885px;
        margin-top: 9%;
    }

    .content1 h2 {
        font-size: 22px;
    }

    .content2-header1 {
        padding: 0.7em;
        width: 80%;
        margin-left: 3%;
    }

    .content2-header1 p {
        font-size: 19px;
    }

    .content2-header1 span {
        font-size: 16px;
    }

    .confirm {
        margin-top: 18%;
        width: 100%;
    }

    .process {
        width: 100%;
        margin: 18% 0 0 0%;
    }

    .quality {
        width: 100%;
        margin: 18% 0 0 0%;
    }

    .dispatch {
        width: 100%;
        margin: 18% 0 0 0%;
    }

    .delivery {
        width: 100%;
        margin: 18% 0 0 0%;
    }

    .imgcircle {
        height: 70px;
        width: 70px;
        margin-left: 35%;
        z-index: 100;

    }

    .imgcircle img {
        height: 30px;
        top: 27%;
        left: 28%;
    }

    span.line {
        width: 6px;
        left: 43.5%;
        height: 58px;
        top: 152%;
    }

    .content3 p {
        font-size: 15px;
        margin: -12% 0 0 -72%;
    }

    .shipment {
        margin-left: 16%;
    }

    .footer {
        padding: 1%;
    }

    .footer p {
        font-size: 16px;
    }
}

@media (max-width: 384px) {
    .header {
        margin-top: 9%;
    }

    .content1 h2 {
        font-size: 21px;
    }

    .content3 p {
        margin: -13% 0 0 -74%;
    }

    .shipment {
        margin-top: -11%;
    }

    span.line {
        top: 154%;
        left: 44%;
        height: 52px;
    }

    .content {
        height: 845px;
    }

    .footer {
        padding: 3%;
    }

    .footer p {
        font-size: 15px;
    }
}

@media (max-width: 375px) {
    .content {
        height: 840px;
    }

    .content1 h2 {
        font-size: 20px;
    }

    span.line {
        top: 149%;
        left: 44.5%;
        height: 51px;
    }

    .shipment {
        margin-left: 17%;
    }
}

@media (max-width: 320px) {
    .header {
        margin-top: 10%;
    }

    .content {
        margin-top: 10%;
    }

    .content1 {
        padding: 1em;
    }

    .header h1 {
        font-size: 31px;
    }

    .content {
        height: 830px;
    }

    .content1 h2 {
        font-size: 17px;
    }

    .content2-header1 span {
        font-size: 15px;
    }

    .content3 p {
        margin: -16% 0 0 -79%;
    }

    .shipment {
        margin-left: 19%;
    }

    span.line {
        top: 115%;
        left: 46%;
    }

    .footer {
        margin-top: 1%;
    }

    .footer p {
        font-size: 14px;
    }
}

.content2 {
    background-color: #2da9bb;
    overflow: hidden;
}

.trackId {
    width: 220px;
}

/*#search label {*/
/*    position: absolute;*/
/*    left: 13px;*/
/*    top: 26px;*/
/*}*/

#search #search-input {
    box-shadow: none !important;
    font-size: 20px;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #28bcad;
}

/*#search #search-input, #search .hint {*/
/*    padding-left: 43px;*/
/*    padding-right: 43px;*/
/*    border-radius: 23px;*/
/*}*/

/*#search #search-clear {*/
/*    text-decoration: none;*/
/*    position: absolute;*/
/*    right: 13px;*/
/*    top: 30px;*/
/*    color: #b3b3b3;*/
/*}*/

header {
    padding: 20px;
    font-weight: 700;
    font-size: 20px;
}

label {
    display: inline-block;
}

button,
button:focus {
    outline: none !important;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    cursor: pointer;
    color: white;
    border-radius: 5px;
    border: none;
}

.nav-link.nav-active {
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    font-weight: 600;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
}

.nav {
    padding: 10px;
    display: -webkit-box;
    flex-wrap: nowrap;
    overflow-x: auto;
    background-color: #28bcad;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.nav::-webkit-scrollbar {
    width: 2px;
    height: 4px;
    background-color: #28bcad;
    margin-top: 10px;
    border: 1px solid #28bcad;
}

.nav::-webkit-scrollbar-thumb {
    background-color: #fff;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

header .container {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.add-to-cart-icon {
    color: white;
}

.code {
    /* for 404 code */
    border-right: 2px solid;
    font-size: 26px;
    padding: 0 15px 0 15px;
    text-align: center;
}

.cart-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .15rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .2s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-decoration: none;
    outline: none;
}

.cart-btn-success {
    color: #fff;
    background-color: #28bcad;
    border: 2px solid #28bcad;
}

.cart-btn-success:hover {
    background-color: #FFF;
    color: #28bcad;
}

.cart-btn-success:hover .add-to-cart-icon {
    color: #28bcad;
}

.cart-btn:focus,
.cart-btn:hover {
    text-decoration: none;
}

.cart-btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.cart-btn-add {
    background-color: #28bcad;
}

.cart-remove {
    background: transparent;
    border: none;
    font-size: 18px;
    position: absolute;
    top: 0;
    padding: 10px;
    right: 2px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    outline: none;
}

.cart-item-qty {
    width: 55px;
    border-radius: 3px;
    font-size: 12px;
    padding: 4px 0 3px 6px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.cart-product-item-section .add-note-section {
    width: 50%;
    margin-right: 20px;
}

.logo {
    height: 100px;
}

.float-btn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 50px;
    right: 40px;
    background-color: #28bcad;
    border: 2px solid #28bcad;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px rgb(116, 114, 114);
    transition: 0.4s ease-in-out;
    outline: none;
}

.float-btn:focus {
    box-shadow: none;
}

.float-btn:hover {
    background-color: #FFF;
}

.cart-icon {
    color: white;
    font-size: 30px;
}

.float-btn:hover .cart-icon {
    color: #28bcad;
}

.float-btn .float-count {
    position: absolute;
    padding: 5px;
    height: 25px;
    min-width: 25px;
    top: -15px;
    left: 15px;
    border-radius: 50px;
    color: white;
    background-color: red;
}

.form-control-range:focus {
    outline: none !important;
}

.product-details {
    position: relative;
    margin-bottom: 25px;
    padding: 0 15px;
    width: 25%;
}

.product-details-pro-grid-2 {
    position: relative;
    margin-bottom: 25px;
    padding: 0 15px;
    width: 25%;
}

.product-name {
    font-size: 15px;
}

.promo-code-section {
    width: 40%;
}

.switch-field {
    display: flex;
    justify-content: center;
    overflow: auto;
}

.switch-field input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
}

.switch-field input[type="radio"]:checked+label {
    background-color: #28bcad;
    color: #fff;
}

.switch-field label:not(:last-of-type) {
    margin-right: 10px;
}

.switch-field label {
    height: 70px;
    width: 100px;
    border: 3px solid #28bcad;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #28bcad;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.special-offer-card {
    width: 100%;
}

.special-offer-product-image {
    height: auto;
    max-height: 300px;
    width: 320px;
}

.special-offer-description {
    max-width: 320px;
}

.special-offer-banner {
    border-radius: 50%;
    margin-top: -50px;
    width: 200px;
    height: 200px;
}

.the_excerpt_desc {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.default-view-description.the_excerpt_desc {
    -webkit-line-clamp: 3;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .product-details,
    .product-details-pro-list {
        width: 100%;
    }

    .product-details-pro-grid-2 {
        width: 50%;
        padding: 0 4px;
    }

    .product-details-pro-grid-2 .product-img {
        height: 120px;
    }

    .product-details-pro-grid-2 .product-img-placeholder {
        height: 120px;
    }

    .switch-field {
        justify-content: flex-start;
    }

    .special-offer-card {
        width: 100%;
    }

    .special-offer-product-image {
        height: auto;
        max-height: 170px;
        width: 200px;
    }

    .special-offer-description {
        max-width: 200px;
    }

    .special-offer-banner {
        margin-top: -40px;
        width: 100px;
        height: 100px;
    }

    .float-btn {
        width: 45px;
        height: 45px;
        bottom: 45px;
        right: 20px;
    }

    .cart-icon {
        font-size: 24px;
    }

    .cart-product-item-section {
        flex-direction: column;
    }

    .cart-product-item-section .add-note-section {
        width: 100%;
        margin-bottom: 10px;
    }

    .float-btn .float-count {
        position: absolute;
        padding: 3px;
        height: 18px;
        min-width: 18px;
        top: -10px;
        left: 12px;
        border-radius: 50px;
        color: white;
        background-color: red;
        font-size: 12px;
    }

    .product-name {
        font-size: 14px;
    }

    .product-details-pro-grid-2 .product-name {
        font-size: 14px;
    }

    .promo-code-total-section {
        flex-wrap: wrap;
    }

    .promo-code-section {
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ced4da;
    }

    .product-item .cart-btn {
        padding: 1px 8px;
        font-size: 11px;
    }

    .the_excerpt_desc {
        -webkit-line-clamp: 2;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .product-details,
    .product-details-pro-grid-2 {
        width: 50%;
    }

    .product-details-pro-list {
        width: 100%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .product-details,
    .product-details-pro-grid-2 {
        width: 33%;
    }

    .product-details-pro-list {
        width: 50%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .product-details,
    .product-details-pro-grid-2 {
        width: 25%;
    }

    .product-details-pro-list {
        width: 45%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .product-details,
    .product-details-pro-grid-2 {
        width: 25%;
    }

    .product-details-pro-list {
        width: 31%;
    }
}

.product-item {
    height: 100%;
    position: relative;
    box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding-bottom: 10px;
    transition: transform .4s;
}

.product-item:hover {
    transform: scale(1.01);
}

.product-img {
    width: 100%;
    height: 250px;
}

.product-img-placeholder {
    width: 100%;
    height: 250px;
    background-color: #F9F9F9;
}

@keyframes animate {
    0% {
        transform: scale(0.7, 0.7);
    }

    50% {
        transform: scale(1.2, 1.2);
    }

    100% {
        transform: scale(0.7, 0.7);
    }
}

@keyframes special-offer-animate {
    0% {
        transform: scale(0.9, 0.9);
    }

    50% {
        transform: scale(1.03, 1.03);
    }

    100% {
        transform: scale(0.9, 0.9);
    }
}


.zoom-in-out-animation {
    animation: animate 1s ease-in-out infinite
}

.special-offer-zoom-in-out {
    animation: special-offer-animate 1s ease-in-out infinite
}

.no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
}

.highlight {
    animation: highlight 1s;
}

.trash {
    transition: 0.3s color;
}

.trash:hover {
    color: var(--danger-light);
}

.is-animated {
    -webkit-animation: .6s zoom-in;
    animation: .6s zoom-in;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.bg-white {
    background-color: var(--white);
}

.bg-default {
    background-color: var(--background-light);
}

.border-1-l {
    border-color: var(--border-1-light);
}

.border-2-l {
    border-color: var(--border-2-light);
}

.border-3-l {
    border-color: var(--border-3-light);
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.flex {
    display: -webkit-box;
    display: flex;
}

.inline-flex {
    display: -webkit-inline-box;
    display: inline-flex;
}

.table {
    display: table;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
}

.hidden {
    display: none;
}

.flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.flex-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}

.flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.flex-col-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

.items-start {
    -webkit-box-align: start;
    align-items: flex-start;
}

.items-end {
    -webkit-box-align: end;
    align-items: flex-end;
}

.items-center {
    -webkit-box-align: center;
    align-items: center;
}

.items-baseline {
    -webkit-box-align: baseline;
    align-items: baseline;
}

.items-stretch {
    -webkit-box-align: stretch;
    align-items: stretch;
}

.self-auto {
    align-self: auto;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-center {
    align-self: center;
}

.self-stretch {
    align-self: stretch;
}

.justify-start {
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.justify-end {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.justify-center {
    -webkit-box-pack: center;
    justify-content: center;
}

.justify-between {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.content-center {
    align-content: center;
}

.content-start {
    align-content: flex-start;
}

.content-end {
    align-content: flex-end;
}

.content-between {
    align-content: space-between;
}

.content-around {
    align-content: space-around;
}

.flex-1 {
    -webkit-box-flex: 1;
    flex: 1 1 0%;
}

.flex-auto {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
}

.flex-initial {
    -webkit-box-flex: 0;
    flex: 0 1 auto;
}

.flex-none {
    -webkit-box-flex: 0;
    flex: none;
}

.flex-full {
    -webkit-box-flex: 1;
    flex: 1 1 100%;
}

.flex-3\/4 {
    -webkit-box-flex: 1;
    flex: 1 1 75%;
}

.flex-1\/2 {
    -webkit-box-flex: 1;
    flex: 1 1 50%;
}

.flex-1\/3 {
    -webkit-box-flex: 1;
    flex: 1 1 33.333333%;
}

.flex-1\/4 {
    -webkit-box-flex: 1;
    flex: 1 1 25%;
}

.flex-grow-0 {
    -webkit-box-flex: 0;
    flex-grow: 0;
}

.flex-grow {
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-shrink {
    flex-shrink: 1;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.float-none {
    float: none;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.font-100 {
    font-weight: 100;
}

.font-200 {
    font-weight: 200;
}

.font-300 {
    font-weight: 300;
}

.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.font-bold {
    font-weight: bold;
}

.font-normal {
    font-weight: normal;
}

.h-auto {
    height: auto;
}

.h-full {
    height: 100%;
}

.h-screen {
    height: 100vh;
}

.m-0 {
    margin: 0;
}

.m-5 {
    margin: 5px;
}

.m-10 {
    margin: 10px;
}

.m-15 {
    margin: 15px;
}

.m-20 {
    margin: 20px;
}

.m-25 {
    margin: 25px;
}

.m-30 {
    margin: 30px;
}

.m-auto {
    margin: auto;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.mx-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mx-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mx-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mx-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.my-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.mx-25 {
    margin-left: 25px;
    margin-right: 25px;
}

.my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.mx-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.mt-0 {
    margin-top: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.ml-0 {
    margin-left: 0;
}

.mt-5 {
    margin-top: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.ml-15 {
    margin-left: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mr-25 {
    margin-right: 25px;
}

.mb-25 {
    margin-bottom: 25px;
}

.ml-25 {
    margin-left: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-30 {
    margin-right: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.mt-auto {
    margin-top: auto;
}

.mr-auto {
    margin-right: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.ml-auto {
    margin-left: auto;
}

.max-h-full {
    max-height: 100%;
}

.max-w-200 {
    max-width: 200px;
}

.max-w-300 {
    max-width: 300px;
}

.max-w-400 {
    max-width: 400px;
}

.max-w-500 {
    max-width: 500px;
}

.max-w-full {
    max-width: 100%;
}

.min-h-0 {
    min-height: 0;
}

.min-h-full {
    min-height: 100%;
}

.min-h-screen {
    min-height: 100vh;
}

.min-w-0 {
    min-width: 0;
}

.min-w-full {
    min-width: 100%;
}

.object-contain {
    object-fit: contain;
}

.object-cover {
    object-fit: cover;
}

.object-fill {
    object-fit: fill;
}

.object-none {
    object-fit: none;
}

.object-scale-down {
    object-fit: scale-down;
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: .25;
}

.opacity-50 {
    opacity: .5;
}

.opacity-60 {
    opacity: .6;
}

.opacity-75 {
    opacity: .75;
}

.opacity-100 {
    opacity: 1;
}

.outline-none {
    outline: 0;
}

.focus\:outline-none:focus {
    outline: 0;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-visible {
    overflow-x: visible;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.scrolling-touch {
    -webkit-overflow-scrolling: touch;
}

.scrolling-auto {
    -webkit-overflow-scrolling: auto;
}

.p-0 {
    padding: 0;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.p-20 {
    padding: 20px;
}

.p-25 {
    padding: 25px;
}

.p-30 {
    padding: 30px;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.px-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.px-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.px-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.px-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.py-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.px-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.px-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.pt-0 {
    padding-top: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.pt-5 {
    padding-top: 5px;
}

.pr-5 {
    padding-right: 5px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pl-5 {
    padding-left: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pr-15 {
    padding-right: 15px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pl-15 {
    padding-left: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pr-25 {
    padding-right: 25px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pl-25 {
    padding-left: 25px;
}

.pt-30 {
    padding-top: 30px;
}

.pr-30 {
    padding-right: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pl-30 {
    padding-left: 30px;
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.static {
    position: static;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
}

.inset-y-0 {
    top: 0;
    bottom: 0;
}

.inset-x-0 {
    right: 0;
    left: 0;
}

.inset-y-auto {
    top: auto;
    bottom: auto;
}

.inset-x-auto {
    right: auto;
    left: auto;
}

.top-0 {
    top: 0;
}

.top-20 {
    top: 20px;
}

.right-0 {
    right: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.top-auto {
    top: auto;
}

.right-auto {
    right: auto;
}

.bottom-auto {
    bottom: auto;
}

.left-auto {
    left: auto;
}

.t-h1 {
    font-family: 'IBM Plex Sans';
    font-size: 32px;
    font-weight: normal;
}

.t-h2 {
    font-family: 'IBM Plex Sans';
    font-size: 24px;
    font-weight: normal;
}

.t-h3,
.t-title {
    font-family: 'IBM Plex Sans';
    font-size: 18px;
    font-weight: 500;
}

.t-h4,
.t-subtitle1 {
    font-family: 'IBM Plex Sans';
    font-size: 16px;
    font-weight: 500;
}

.t-h5,
.t-subtitle2 {
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 500;
}

.t-h6,
.t-badge {
    font-weight: 500;
    font-size: 12px;
}

.t-body1 {
    font-size: 16px;
}

.t-body2 {
    font-size: 14px;
}

.t-button {
    font-weight: 700;
    font-size: 14px;
}

.t-overline {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-xs {
    font-size: .75rem;
}

.text-sm {
    font-size: .875rem;
}

.text-base {
    font-size: 1rem;
}

.text-lg {
    font-size: 1.2rem;
}

.text-xl {
    font-size: 1.3rem;
}

.text-2xl {
    font-size: 1.5rem;
}

.text-3xl {
    font-size: 1.875rem;
}

.text-4xl {
    font-size: 2.25rem;
}

.text-5xl {
    font-size: 3rem;
}

.italic {
    font-style: italic;
}

.not-italic {
    font-style: normal;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.normal-case {
    text-transform: none;
}

.underline {
    text-decoration: underline;
}

.line-through {
    text-decoration: line-through;
}

.no-underline {
    text-decoration: none;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.select-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.select-all {
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
}

.select-auto {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.align-baseline {
    vertical-align: baseline;
}

.align-top {
    vertical-align: top;
}

.align-middle {
    vertical-align: middle;
}

.align-bottom {
    vertical-align: bottom;
}

.align-text-top {
    vertical-align: text-top;
}

.align-text-bottom {
    vertical-align: text-bottom;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.whitespace-normal {
    white-space: normal;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

.whitespace-pre {
    white-space: pre;
}

.whitespace-pre-line {
    white-space: pre-line;
}

.whitespace-pre-wrap {
    white-space: pre-wrap;
}

.break-normal {
    overflow-wrap: normal;
    word-break: normal;
}

.break-words {
    overflow-wrap: break-word;
}

.break-all {
    word-break: break-all;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.w-auto {
    width: auto;
}

.w-1\/2 {
    width: 50%;
}

.w-1\/3 {
    width: 33.33333%;
}

.w-2\/3 {
    width: 66.66667%;
}

.w-1\/4 {
    width: 25%;
}

.w-3\/4 {
    width: 75%;
}

.w-1\/5 {
    width: 20%;
}

.w-2\/5 {
    width: 40%;
}

.w-3\/5 {
    width: 60%;
}

.w-4\/5 {
    width: 80%;
}

.w-full {
    width: 100%;
}

.w-screen {
    width: 100vw;
}

.z-0 {
    z-index: 0;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.z-auto {
    z-index: auto;
}

/* Custom */

.cp {
    cursor: pointer;
}

.center-x-y {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.center-x {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.center-y {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

@media screen and (max-width:500px) {
    #responsiveProduct {
        flex-direction: column;
        gap: 5px;
    }

    #responsiveProduct>div:not(:first-child) {
        width: 100%;
        text-align: center;
    }

    #responsiveProduct>button {
        margin-right: 0px;
    }

    #outercontainer {
        width: 85%;
    }

    #shimmerdefault {
        margin: 0 auto;
    }
}

.disable-actions {
    pointer-events: none;
    opacity: .6;
}

.rounded-none {
    border-radius: 0;
}

.br-4 {
    border-radius: 4px;
}

.br-10 {
    border-radius: 10px;
}

.rounded {
    border-radius: 50%;
}

.break-word {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.grab {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab;
}

/* Apply a "closed-hand" cursor during drag operation. */

.grab:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
}

.t-caption {
    font-size: 12px;
}

.text-primary {
    color: var(--text-primary)
}

.text-secondary {
    color: var(--text-secondary)
}

.text-info {
    color: var(--text-info)
}

.primary-main {
    color: var(--primary-main);
}

.primary-dark {
    color: var(--primary-dark)
}

.hover-link:hover {
    color: var(--primary-main)
}

.hover-bg:hover {
    background: rgba(0, 0, 0, 0.07);
}

.border-1 {
    border: 1px solid rgba(195, 196, 204, 0.77);
}

.border {
    border-width: 1px;
    border-style: solid;
}

.border-bottom-1 {
    border-bottom: 1px solid rgba(195, 196, 204, 0.77);
}

.border-top-1 {
    border-top: 1px solid rgba(195, 196, 204, 0.77);
}