.shine {
    background: linear-gradient(to right, #f6f7f8 0%, #e6e7ea 25%, #f6f7f8 80%);
    background-size: 800px 277px;
    display: inline-block;
    position: relative;

    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    -webkit-animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
}

.line {
    border-radius: 4px;;
    height: 10px;
    width: 90%;
    margin: 10px auto auto;
}

.placeholder-button {
    border-radius: 4px;;
    height: 30px;
    width: 100px;
    margin: 10px auto auto;
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -800px 0;
    }

    100% {
        background-position: 800px 0;
    }
}

@keyframes placeholderShimmer {
0% {
    background-position: -800px 0;
}

100% {
    background-position: 800px 0;
}
}
