.popup-overlay {
    background: rgba(0,0,0,0.5);
}

.custom-modal {
    background: white;
    font-size: 12px;
}

.popup-content {
    background-color: #F4F4F4;
    color: #17777a;
    border: 1px solid #17777a;
}

.custom-modal > .header {
    width: 100%;
    font-size: 18px;
    text-align: center;
    padding: 10px;
}
.custom-modal > .content {
    width: 100%;
    padding: 40px;
}
.custom-modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}
.custom-modal > .close-modal {
    cursor: pointer;
    position: absolute;
    padding: 10px;
    line-height: 20px;
    right: 10px;
    top: 0;
    font-size: 24px;
    /*border: 1px solid #cfcece;*/
}


/* animation */

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}
.popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
